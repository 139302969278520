<template>
  <component
    :is="href ? 'a' : 'button'"
    :class="[variant, color, shape, { active }]"
    :disabled="disabled || loading"
    :href="href"
    :type="type"
    @click="onClick"
  >
    <slot name="leading">
      <Icon v-if="icon || loading" :name="loading ? 'loader' : icon" :spin="loading" />
    </slot>
    <span v-if="$slots.default">
      <slot />
    </span>
    <slot name="trailing" />
  </component>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";
import ShortcutMixin from "@/components/studio/aside/shortcuts/ShortcutMixin.js";

export default {
  name: "Button",
  components: { Icon },
  mixins: [ShortcutMixin],
  props: {
    color: {
      type: "primary" | "secondary" | "default" | "success" | "danger" | "info" | "warning" | "light",
      default: "default",
    },
    variant: {
      type: "text" | "contained" | "outlined",
      default: "text",
    },
    shape: {
      type: "circle" | "rounded",
      default: "rounded",
    },
    type: { type: "submit" | "button", default: "button" },
    icon: { type: String, default: "" },
    href: { type: String, default: "" },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ["click"],
  methods: {
    onClick(event) {
      if (!this.disabled) this.submit(event);
    },
    submit(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "styles/colors/by-class";

button,
a {
  @extend .color-by-class;
  appearance: none;
  display: flex;
  align-items: center;
  height: 2rem;
  width: fit-content;
  font-size: 1em;
  font-weight: 700;
  border-radius: 0.7rem;
  padding-inline: 0.7rem;
  gap: 0.5rem;
  white-space: nowrap;
  border: none;
  outline: none;
  color: var(--bg-color);
  background-color: transparent;
  text-decoration: none;

  &.circle {
    height: 1.6rem;
    border-radius: 5rem;
    padding: 0.35rem;
  }

  &.default {
    &.text,
    &.outlined {
      color: var(--color);

      .icon-container {
        fill: var(--color);
      }

      &:not(:disabled) {
        &.active,
        &:hover {
          background-color: var(--bg-color);
        }
      }
    }

    &.outlined {
      outline: 1px solid var(--separator);
    }
  }

  &:not(:disabled).active,
  &:not(:disabled):hover {
    background-color: color-mix(in srgb, var(--bg-color) 10%, transparent);
  }

  & > * {
    z-index: 1;
  }

  .icon-container {
    height: 100%;
    max-height: 1rem;
    width: auto;
    aspect-ratio: 1;
    fill: var(--bg-color);

    .icon {
      height: 100%;
      width: 100%;
    }
  }

  &.contained {
    color: var(--color);
    background-color: var(--bg-color);

    &:not(:disabled).active,
    &:not(:disabled):hover {
      background-color: color-mix(in srgb, var(--bg-color) 90%, var(--black));
    }

    .icon-container {
      fill: var(--color);
    }
  }

  &.outlined {
    outline: 1px solid var(--bg-color);
  }

  &:disabled {
    cursor: not-allowed;
    filter: opacity(95%) saturate(75%);
  }
}
</style>
