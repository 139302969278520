var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.href ? "a" : "button",
    {
      tag: "component",
      class: [_vm.variant, _vm.color, _vm.shape, { active: _vm.active }],
      attrs: {
        disabled: _vm.disabled || _vm.loading,
        href: _vm.href,
        type: _vm.type,
      },
      on: { click: _vm.onClick },
    },
    [
      _vm._t("leading", function () {
        return [
          _vm.icon || _vm.loading
            ? _c("Icon", {
                attrs: {
                  name: _vm.loading ? "loader" : _vm.icon,
                  spin: _vm.loading,
                },
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.$slots.default ? _c("span", [_vm._t("default")], 2) : _vm._e(),
      _vm._v(" "),
      _vm._t("trailing"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }