export default class Shortcut {
  keys = [];
  ctrl = false;
  alt = false;
  shift = false;
  hold = 0;
  constructor({ keys = [], ctrl = false, alt = false, shift = false, hold = 0 }) {
    this.keys = keys;
    this.ctrl = ctrl;
    this.alt = alt;
    this.shift = shift;
    this.hold = hold;
  }

  static default() {
    return new Shortcut({});
  }
}
