import Shortcut from "@/components/annotator/interfaces/Shortcut.js";

export default {
  props: {
    shortcut: { type: Shortcut, default: Shortcut.default },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    hasShortcut() {
      return this.shortcut.keys.length > 0;
    },
  },
  data() {
    return {
      delayPassed: false,
      pressed: false,
      pressTimer: undefined,
    };
  },
  mounted() {
    this.addKeyboardListener();
  },
  beforeDestroy() {
    this.removeKeyboardListener();
  },
  watch: {
    hasShortcut(hasShortcut, hadShortcut) {
      if (hadShortcut && !hasShortcut) this.removeKeyboardListener();
      else if (hasShortcut && !hadShortcut) this.addKeyboardListener();
    },
  },
  methods: {
    addKeyboardListener() {
      if (!this.hasShortcut) return;
      if (this.hold) {
        document.addEventListener("keyup", this.onKeyUp);
        document.addEventListener("keydown", this.onKeyDownDelayed);
      } else {
        document.addEventListener("keydown", this.onKeyDown);
      }
    },
    removeKeyboardListener() {
      if (!this.hasShortcut) return;
      if (this.hold) {
        document.removeEventListener("keyup", this.onKeyUp);
        document.removeEventListener("keydown", this.onKeyDownDelayed);
      } else {
        document.removeEventListener("keydown", this.onKeyDown);
      }
      if (this.pressTimer) this.pressTimer.clearTimeout();
    },
    onKeyUp(event) {
      this.pressed = false;
      if (!this.disabled && !this.delayPassed) this.onShortcut(event);
    },
    onKeyDownDelayed() {
      this.delayPassed = false;
      this.pressed = true;
      this.pressTimer = setTimeout(() => {
        if (this.pressed) this.delayPassed = true;
      }, this.hold);
    },
    onKeyDown(event) {
      if (this.disabled) return;
      if (this.checkShortcut(event)) {
        event.preventDefault();
        if (this.submit) this.submit(event);
        else console.error("No submit function defined for shortcut", this.shortcut.keys[0]);
      }
    },
    checkShortcut(event) {
      if (this.shortcut.ctrl && !(event.ctrlKey || event.metaKey)) return false;
      else if (!this.shortcut.ctrl && (event.ctrlKey || event.metaKey)) return false;
      if (this.shortcut.shift && !event.shiftKey) return false;
      else if (!this.shortcut.shift && event.shiftKey) return false;
      if (this.shortcut.alt && !event.altKey) return false;
      else if (!this.shortcut.alt && event.altKey) return false;
      return this.shortcut.keys[0]?.toLowerCase() === event.key.toLowerCase();
    },
  },
};
